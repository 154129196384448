import './App.css';
import Logo from "./assets/logo.svg"
import {useState} from "react";

function App() {
    const [activeLang, setActiveLang] = useState('ru');
    const languages = ['uz','ru','en'];

    return (
        <div className="page-layout">
            <header className="page-header">
                <div className="container">
                    <div className='header__logo'>
                        <img src={Logo} alt=""/>
                        <span>ISHONCH</span>
                    </div>
                    {/*<div className="header__menu">*/}
                    {/*    <div>Политика конфиденциальности</div>*/}
                    {/*</div>*/}
                    <div className="header__language">
                        {languages.map(lang => (
                            <span
                                key={lang}
                                className={lang === activeLang ? '--active' : ''}
                                onClick={() => setActiveLang(lang)}
                            >{lang}</span>
                        ))}
                    </div>
                </div>
            </header>
            <div className='page-content'>
                <div className="container">

                    <video autoPlay={true} muted={true} loop={true} style={{width: '100%'}}>
                        <source src={require('./assets/Ishonch.mp4')} type="video/mp4"/>
                    </video>

                    <h1>Политика конфиденциальности</h1>

                    {activeLang === 'ru' && (
                        <div className='default-content'>
                            <p>1. Изменение и удаление Пользователем персональной информации, а также получение доступа к ней.</p>
                            <p>— Сотрудники вправе самостоятельно передать информацию, полученную от Qurilish в порядке, предусмотренном в настоящем разделе другому контролеру.</p>
                            <p>— Настоящей Политики Qurilish вправе затребовать предоставления дополнительной информации, необходимой для подтверждения личности такого Пользователя.</p>
                            <p>2. Право на возражение:</p>
                            <p>— В пределах, установленных применимым законодательством, Сотрудники может отозвать любое свое согласие, которое им было ранее предоставлено, или представить свои возражения на законных основаниях в отношении обработки его персональной информации. В некоторых ситуациях отзыв согласия будет означать, что Пользователь не сможет воспользоваться Сервисами Qurilish.</p>
                            <p>3. Меры, применяемые для защиты персональной информации сотрудников.</p>
                            <p>— Qurilish принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
                            <p>— Защиту и обработку персональных данных Пользователя Qurilish производит в соответствии с Законом Республики Узбекистан «О персональных данных» (№ЗРУ-547 от 02.07.2019 г.), и других нормативных актов, регулирующих отношения в сфере обеспечения безопасности персональных данных.</p>
                        </div>
                    )}

                    {activeLang === 'uz' && (
                        <div className='default-content'>
                            <p>1. Foydalanuvchi tomonidan shaxsiy ma'lumotlarni o'zgartirish va o'chirish, shuningdek, unga kirish huquqiga ega bo'lish.</p>
                            <p>— Xodimlar “Qurilish”dan olingan ma’lumotlarni ushbu bo‘limda nazarda tutilgan tartibda mustaqil ravishda boshqa nazoratchiga o‘tkazish huquqiga ega.</p>
                            <p>— Ushbu Siyosatga muvofiq, Qurilish bunday Foydalanuvchining shaxsini tasdiqlash uchun zarur bo'lgan qo'shimcha ma'lumotlarni taqdim etishni talab qilish huquqiga ega.</p>
                            <p>2. E'tiroz bildirish huquqi:</p>
                            <p>— Amaldagi qonunchilikda ruxsat etilgan darajada, Xodimlar o'zlarining shaxsiy ma'lumotlarini qayta ishlashga ilgari bergan har qanday roziligini qaytarib olishlari yoki qonuniy asoslarga ko'ra e'tiroz bildirishlari mumkin. Ba'zi hollarda rozilikni bekor qilish foydalanuvchi Qurilish xizmatlaridan foydalana olmasligini anglatadi.</p>
                            <p>3. Xodimlarning shaxsiy ma'lumotlarini himoya qilish bo'yicha ko'rilgan choralar.</p>
                            <p>— Qurilish Foydalanuvchining shaxsiy maʼlumotlariga ruxsatsiz yoki tasodifiy kirish, yoʻq qilish, oʻzgartirish, bloklash, nusxa koʻchirish, tarqatish, shuningdek, u bilan uchinchi shaxslarning boshqa noqonuniy harakatlaridan himoya qilish uchun zarur va yetarlicha tashkiliy-texnik choralarni koʻradi.</p>
                            <p>— “Qurilish” O‘zbekiston Respublikasining “Shaxsiy ma’lumotlar to‘g‘risida”gi Qonuni (02.07.2019 yildagi O‘RQ-547-son) va boshqa normativ hujjatlarga muvofiq Foydalanuvchining shaxsiy ma’lumotlarini himoya qiladi va qayta ishlaydi. Shaxsiy ma'lumotlarning xavfsizligi.</p>
                        </div>
                    )}

                    {activeLang === 'en' && (
                        <div className='default-content'>
                            <p>1. Changing and deleting personal information by the User, as well as gaining access to it.</p>
                            <p>— Employees have the right to independently transfer information received from "Qurilish" in the manner provided for in this section to another controller.</p>
                            <p>— of this Policy, "Qurilish" has the right to request the provision of additional information necessary to confirm the identity of such a User.</p>
                            <p>2. Right to object:</p>
                            <p>— To the extent permitted by applicable law, Employees may withdraw any consent they have previously given or object on legal grounds to the processing of their personal information. In some situations, withdrawal of consent will mean that the User will not be able to use the "Qurilish" Services.</p>
                            <p>3. Measures taken to protect the personal information of employees.</p>
                            <p>— "Qurilish" takes the necessary and sufficient organizational and technical measures to protect the User's personal information from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, as well as from other illegal actions of third parties with it.</p>
                            <p>— "Qurilish" protects and processes the User’s personal data in accordance with the Law of the Republic of Uzbekistan “On Personal Data” (No. ZRU-547 of 07/02/2019), and other regulations governing relations in the field of ensuring the security of personal data.</p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

export default App;
